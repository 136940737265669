* {
  word-spacing: normal !important
}
body {
  font-family: Inter, sans-serif;
  font-size: .85rem
}
.accordion-wrapper .card-header button,
.badge,
.btn,
.btn.btn-circle .number,
.collapse-link,
.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6,
.dropdown-item,
.filter:not(.basic-filter),
.filter:not(.basic-filter) ul li a,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.meta,
.more,
.nav-link,
.post-category,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600
}
.avatar,
.btn,
.dropdown-item,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.more,
.nav-link,
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: normal !important
}
.btn,
.lg-sub-html p,
.nav-link,
.navbar .btn-sm {
  font-size: .85rem !important
}
.dropdown-menu {
  font-size: .8rem !important
}
.btn-group-sm>.btn,
.btn-sm,
.post-meta,
.share-dropdown .dropdown-menu .dropdown-item {
  font-size: .75rem !important
}
.filter,
.filter ul li a,
.meta,
.post-category {
  font-size: .7rem !important
}
.post-header .post-meta {
  font-size: .85rem !important
}
.accordion-wrapper .card-header button,
.collapse-link,
.nav-tabs .nav-link {
  font-size: .9rem !important
}
blockquote {
  font-size: .9rem !important
}
.blockquote-footer {
  font-size: .65rem !important
}
.blockquote-details p {
  font-size: .85rem !important
}
.counter-wrapper p {
  font-size: .85rem !important
}
.counter-wrapper .counter {
  font-size: calc(1.33rem + .96vw) !important
}
@media (min-width:1200px) {
  .counter-wrapper .counter {
    font-size: 2.05rem !important
  }
}
.counter-wrapper .counter.counter-lg {
  font-size: calc(1.35rem + 1.2vw) !important
}
@media (min-width:1200px) {
  .counter-wrapper .counter.counter-lg {
    font-size: 2.25rem !important
  }
}
.icon-list.bullet-bg i {
  top: .25rem !important
}
.accordion-wrapper .card-header button:before {
  margin-top: -.2rem !important
}
.form-floating>label {
  padding-top: .65rem !important
}
.h1,
h1 {
  font-size: calc(1.275rem + .3vw) !important
}
@media (min-width:1200px) {
  .h1,
  h1 {
    font-size: 1.5rem !important
  }
}
.h2,
h2 {
  font-size: calc(1.26rem + .12vw) !important
}
@media (min-width:1200px) {
  .h2,
  h2 {
    font-size: 1.35rem !important
  }
}
.h3,
h3 {
  font-size: 1.15rem !important
}
.h4,
h4 {
  font-size: 1rem !important
}
.h5,
h5 {
  font-size: .95rem !important
}
.h6,
h6 {
  font-size: .9rem !important
}
.fs-sm {
  font-size: .75rem !important
}
.fs-lg {
  font-size: 1.05rem !important
}
.lead {
  font-size: .95rem !important;
  line-height: 1.6 !important
}
.lead.fs-lg {
  font-size: 1.1rem !important;
  line-height: 1.55
}
.display-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
  line-height: 1.15 !important
}
@media (min-width:1200px) {
  .display-1 {
    font-size: 2.5rem !important
  }
}
.display-2 {
  font-size: calc(1.355rem + 1.26vw) !important;
  line-height: 1.2 !important
}
@media (min-width:1200px) {
  .display-2 {
    font-size: 2.3rem !important
  }
}
.display-3 {
  font-size: calc(1.335rem + 1.02vw) !important;
  line-height: 1.2 !important
}
@media (min-width:1200px) {
  .display-3 {
    font-size: 2.1rem !important
  }
}
.display-4 {
  font-size: calc(1.315rem + .78vw) !important;
  line-height: 1.25
}
@media (min-width:1200px) {
  .display-4 {
    font-size: 1.9rem !important
  }
}
.display-5 {
  font-size: calc(1.295rem + .54vw) !important;
  line-height: 1.25
}
@media (min-width:1200px) {
  .display-5 {
    font-size: 1.7rem !important
  }
}
.display-6 {
  font-size: calc(1.275rem + .3vw) !important;
  line-height: 1.3 !important
}
@media (min-width:1200px) {
  .display-6 {
    font-size: 1.5rem !important
  }
}